<template>
  <div class="account-manage">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="text-left">我的账号</span>
      </div>
      <div class="account-info">
        <div class="upper-content">
          <div style="margin-right: 32px">
            <el-upload class="avatar-uploader" :action="uploadUrl" :data="headData" accept=".jpg,.png" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <!-- <img :src="imageUrl" class="avatar" > -->
              <!-- <div v-else class="img_box">上传头像</div> -->
              <el-image class="avatar" :src="imageUrl">
                <div slot="error" class="image-slot">
                  <img style="width: 90px; height: auto; border-radius: 50%" :src="headPortrait" />
                </div>
              </el-image>
            </el-upload>
          </div>
          <div class="" style="flex: 1">
            <div class="privilege-middle">
              <div class="privilege">
                <div class="privilege-mobile">{{ Mobile }}</div>
                <div>
                  <span class="privilege-image" v-if="hasBindEntity && userData.packageName" @click="handleJumpImg">
                    <img src="../../../assets/pc/images/personal/vip_icon_small.png" alt="" style="margin-right: 5px" />
                    会员用户
                  </span>
                  <span class="noPrivilege-image" @click="handleJumpImg" v-else>
                    <img src="../../../assets/pc/images/personal/ordinary.png" alt="" style="margin-right: 5px" />
                    普通用户
                  </span>
                </div>
              </div>
              <div style="margin: 20px 0">
                <span v-if="userData.name">{{ userData.name }}</span>
                <span v-else>
                  <span style="font-size: 16px; color: #b0b0b0">未绑定主体&nbsp;</span>
                  <span style="font-size: 15px; color: #156ED0; cursor: pointer" @click="goToBindEntity">立即绑定&gt;</span>
                </span>
              </div>
              <div style="color: #999" v-if="
                  hasBindEntity &&
                  (userData.packageName === '一年会员套餐' ||
                    userData.packageName === '体验会员套餐' ||
                    userData.packageName === '两年会员套餐' ||
                    userData.packageName === '三年会员套餐' ||
                    userData.packageName === '超值套餐')
                ">
                有效日期： {{ userData.startTime }} 至{{ userData.endTime }}
              </div>
            </div>
          </div>
          <div>
            <span class="resetPaw" @click="resetPwdDialogVis = true"><i class="el-icon-lock"></i>修改密码</span>
          </div>
        </div>
      </div>
    </el-card>
    <!-- <account-bind
      v-if="userType == '正式会员'"
      :formSubAccount="formSubAccount"
    ></account-bind> -->
    <!--    修改密码对话框-->
    <reset-pwd-dialog v-model="resetPwdDialogVis" :userData="userDataReset"></reset-pwd-dialog>
    <!-- <has-bind-entity-dialog v-model="openBindDialog"></has-bind-entity-dialog> -->
    <el-dialog title="绑定主体" :visible.sync="dialogVisible" width="500px">
      <el-form ref="form" :model="form" class="form" :rules="rule" label-position="top">
        <el-form-item prop="name" label="主体名称">
          <el-autocomplete class="inline-input" v-model="form.name" :fetch-suggestions="querySearch" placeholder="请输入机构执照名称" :trigger-on-focus="false" @select="handleSelect" :debounce="0" style="width: 100%" clearable>
          </el-autocomplete>
        </el-form-item>
        <el-form-item :label="form.label" v-if="form.type == '0'">
          <div class="creditCode">
            <div style="flex: 1">{{ form.code }}</div>
            <div class="div-code">
              <el-input v-model="form.first_code" maxlength="1" ref="inputRef1"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.second_code" maxlength="1" ref="inputRef2"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.third_code" maxlength="1" ref="inputRef3"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.fourth_code" maxlength="1" ref="inputRef4"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="form.label" v-else>
          <el-input v-model="form.codeInput" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
        </el-form-item>
      </el-form>
      <div class="bind-btn-box">
        <button class="bind-btn" style="width: 280px" @click="bindEntity">
          立即绑定
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "../../../network";
import { mapState } from "vuex";
import resetPwdDialog from "./dialogs/resetPwdDialog";
import {
  getEntityId,
  getMemberId,
  getMobile,
  getVip,
} from "@/assets/public/utils/token";
// import hasBindEntityDialog from '../../../components/pc/hasBindEntityDialog'
// import accountBind from "./components/accountBind.vue";
export default {
  data() {
    return {
      userData: {},
      fileDownloadNum: {},
      isMasterAccount: {
        accountNum: 0,
        isMain: "",
        mobile: "",
        id: "",
      },
      findBindData: [],
      sonList: [],
      resetPwdDialogVis: false,
      hasBindEntity: true,
      isVip: "",
      openBindDialog: false,
      companyName: "",
      dialogVisible: false,
      form: {
        name: "",
        code: "",
        first_code: "",
        second_code: "",
        third_code: "",
        fourth_code: "",
        label: "统一社会信用代码",
        type: "1",
        codeInput: "",
        creditCode: "",
        area: "",
        estiblishTime: "",
        regCapital: "",
        legalPersonName: "",
      },
      rule: {
        name: [
          { required: true, message: "请输入机构执照全称", trigger: "blur" },
        ],
      },
      formationInfo: {},
      headData: {
        userId: getMemberId(),
      },
      imageUrl: "",
      headPortrait: "",
      formSubAccount: {
        startTime: null,
        endTime: null,
      },
      userType: "",
    };
  },
  components: {
    resetPwdDialog,
    // hasBindEntityDialog,
    // accountBind,
  },
  created() {
    const id = JSON.parse(localStorage.getItem("userinfo")).entityId;
    const id2 = JSON.parse(sessionStorage.getItem("userinfo"));
    if (id === 0) {
      if (id2) {
        this.getAllData();
      } else {
        this.getAccountInformation().then(() => {
          if (JSON.stringify(this.userData) !== "{}" || this.userData) {
            this.isVip = this.userData.packageName;
          } else {
            this.isVip = "";
          }
        });
        this.hasBindEntity = false;
      }
    } else {
      this.getAllData();
    }
    getVip(request).then((res) => { });
  },
  mounted() {
    this.updateTokenMessage();
    this.getLoginInfo();
  },
  computed: {
    ...mapState({
      sonUnboundDialogState: (state) => state.personal.sonUnboundDialogState,
      addSonDialogState: (state) => state.personal.addSonDialogState,
      mainUnboundHasSonState: (state) => state.personal.mainUnboundHasSonState,
      sonUnboundSelfDialogState: (state) =>
        state.personal.sonUnboundSelfDialogState,
      avatar: (state) => state.personal.imgUrl,
    }),
    Mobile() {
      return getMobile();
    },
    userDataReset() {
      const userDataReset = {};
      userDataReset.mobile = this.Mobile;
      return userDataReset;
    },
    uploadUrl() {
      return `${process.env.VUE_APP_BASEURL}/pcp/user/uploadAvatar`;
    },
  },
  methods: {
    autoFocus(index, e) {
      const autoIndex = e !== "" ? index + 1 : index - 1;
      if (autoIndex <= 4 && autoIndex >= 1) {
        this.$refs[`inputRef${autoIndex}`].focus();
      }
    },
    getEntityId() {
      return localStorage.getItem("userinfo")
        ? JSON.parse(sessionStorage.getItem("userinfo")).entityId
        : JSON.parse(sessionStorage.getItem("userinfo")).entityId;
    },
    getAllData() {
      this.getAccountInformation().then(() => {
        // this.getCountFileDownload();
        if (JSON.stringify() !== "{}") {
          this.isVip = this.userData.packageName;
        } else {
          this.isVip = "";
        }
      });
    },
    async getAccountInformation() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/PersonalCenter/accountInformation",
          params: {
            id: getMemberId(),
          },
        });
        if (res.code !== 200) return this.$message.error("信息获取失败");
        if (res.data.length > 0) {
          this.userData = res.data[0];
          this.formSubAccount.startTime = res.data[0].startTime;
          this.formSubAccount.endTime = res.data[0].endTime;
          this.userType = !res.data[0].packageName
            ? "普通用户"
            : res.data[0].packageName === "体验会员套餐"
              ? "体验会员"
              : "正式会员";
          if (res.data[0].name) {
            this.getFindInformation();
            this.findBind();
            if (this.userType !== "普通用户") this.getIsMasterAccount();
          }
          const str = JSON.parse(localStorage.getItem("userinfo"));
          str.entityId = res.data[0].entityId;
          const str2 = JSON.stringify(str);
          localStorage.setItem("userinfo", str2);
          sessionStorage.setItem("userinfo", str2);
        } else {
          this.userData = {};
        }
        return Promise.resolve();
      }
    },
    async getCountFileDownload() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/countFileDownload",
        params: {
          entityId: getEntityId(),
        },
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      this.fileDownloadNum = res.data;
    },
    async getIsMasterAccount() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/PersonalCenter/isMasterAccount",
          params: {
            id: getMemberId(),
            entityId: getEntityId(),
          },
        });
        if (res.code !== 200) return this.$message.error("信息获取失败");
        this.isMasterAccount = res.data[0];
      }
    },
    async findBind() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findBind",
        params: {
          entityId: getEntityId(),
        },
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      this.findBindData = res.data;
      this.sonList = this.findBindData.filter((item) => {
        return item.id !== getMemberId();
      });
    },
    goToBindEntity() {
      // this.openBindDialog = true
      this.dialogVisible = true;
    },
    async bindMember(
      area,
      estiblishTime,
      regCapital,
      legalPersonName,
      entityName,
      code
    ) {
      const { data: res } = await request({
        method: "post",
        url: "/pcp/PersonalCenter/newbindmemberone",
        data: {
          area: area,
          estiblishTime: estiblishTime,
          regCapital: regCapital,
          legalPersonName: legalPersonName,
          memberId: getMemberId(),
          entityName: entityName,
          code: code,
          sysType: 1,
        },
      });
      if (res.code !== 200) this.$message.error("主体名称或信用代码不正确");
      // return this.$message.error('主体名称或信用代码不正确')
      // this.hasBindEntity = true
      this.dialogVisible = false;
      this.$message.success("绑定成功");
      this.getAccountInformation();
    },
    bindEntity() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            target: "account-manage",
            lock: true,
            text: "加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          if (this.form.type === "0") {
            if (
              this.form.first_code === this.form.creditCode[14] &&
              this.form.second_code === this.form.creditCode[15] &&
              this.form.third_code === this.form.creditCode[16] &&
              this.form.fourth_code === this.form.creditCode[17]
            ) {
              this.bindMember(
                this.form.area,
                this.form.estiblishTime,
                this.form.regCapital,
                this.form.legalPersonName,
                this.form.name,
                this.form.creditCode
              ).then(() => {
                loading.close();
              });
            } else {
              loading.close();
              this.$message.error("请补全后四位");
            }
          } else {
            if (
              this.form.type === "1" &&
              this.form.codeInput &&
              this.form.codeInput.length === 18
            ) {
              this.bindMember(
                this.form.area,
                this.form.estiblishTime,
                this.form.regCapital,
                this.form.legalPersonName,
                this.form.name,
                this.form.creditCode
              ).then(() => {
                loading.close();
              });
            } else {
              loading.close();
              this.$message.error("请输入18位社会信用代码");
            }
          }
        }
      });
    },
    querySearch(queryString, cb) {
      request({
        method: "GET",
        url: "/pcp/PersonalCenter/getentitynameandcode",
        params: {
          entityName: queryString,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.items.forEach((v) => {
              v.value = v.name;
            });
            let results = [];
            res.data.data.items.map((item) => {
              // results.push(Object.assign({},{value:item.name}))
              results.push(item);
            });
            if (results.length === 0) {
              results = [{ value: "暂无数据" }];
            }
            cb(results);
          }
        })
        .catch((err) => console.log(err));
    },
    handleSelect(item) {
      this.form.code = item.creditCode.slice(0, 6) + "******";
      this.form.label = "请补全统一社会信用代码";
      this.form.type = "0";
      this.form.creditCode = item.creditCode;
      this.form.area = item.base;
      this.form.estiblishTime = item.estiblishTime;
      this.form.regCapital = item.regCapital;
      this.form.legalPersonName = item.legalPersonName;
      this.form.name = item.name;
    },
    // 根据token获取用户信息
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return this.$message.error("获取用户个人信息失败");
    },
    // 获取主体信息
    async getFindInformation() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findInformation",
        params: {
          memberId: getMemberId(),
        },
      });
      if (res.code !== 200) return console.log(res.msg);
      this.formationInfo = res.data;
      this.input_nickname = res.data.contact_name;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.updateTokenMessage();
    },
    beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["jpg", "png", "JPG", "PNG"];
      const isLt10M = Number(file.size / 1024 / 1024);
      if (whiteList.indexOf(fileSuffix) === -1)
        this.$message.error("上传文件只能是 pdf、png格式");
      if (isLt10M > 10) this.$message.error("上传头像图片大小不能超过 10MB!");
      return whiteList && isLt10M;
    },
    async updateTokenMessage() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/updateTokenMessage",
      });
      if (res.code === 200) {
        this.imageUrl = res.data.avatar;
        this.$store.commit("personal/setImgUrl", res.data.avatar);
      }
    },
    handleJumpImg() {
      if ( !this.userData.packageName) {
        this.$message({
          message: "已向客服发送请求成为协会会员通知",
          type: "success",
        });
      }

    },
  },
  watch: {
    sonUnboundDialogState() {
      this.getAllData();
    },
    addSonDialogState() {
      this.getAllData();
    },
    mainUnboundHasSonState() {
      this.getAllData();
    },
    sonUnboundSelfDialogState() {
      this.getAllData();
    },
    "form.name"(val) {
      if (!val) {
        this.form.code = "";
        this.form.type = "1";
        this.form.label = "统一社会信用代码";
      }
    },
    "form.first_code"(val) {
      if (val !== "") {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    "form.second_code"(val) {
      if (val !== "") {
        this.$refs[`inputRef${3}`].focus();
      } else {
        this.$refs[`inputRef${1}`].focus();
      }
    },
    "form.third_code"(val) {
      if (val !== "") {
        this.$refs[`inputRef${4}`].focus();
      } else {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    "form.fourth_code"(val) {
      if (val === "") {
        this.$refs[`inputRef${3}`].focus();
      }
    },
    avatar: {
      handler(val) {
        if (JSON.parse(localStorage.getItem("userinfo"))) {
          if (!val && !JSON.parse(localStorage.getItem("userinfo")).avatar) {
            this.headPortrait = require("../../../assets/pc/images/menu/headpic1080.png");
          } else {
            this.headPortrait =
              val || JSON.parse(localStorage.getItem("userinfo")).avatar;
          }
        } else {
          this.headPortrait = require("../../../assets/pc/images/menu/headpic1080.png");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.binding-card {
  margin-top: 20px;
}
.text-left {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.box-card {
  margin-bottom: 20px;
}
/deep/.el-card__body {
  padding: 0 30px;
}
.upper-content {
  display: flex;
  box-sizing: border-box;
  margin-top: 30px;
  height: 160px;
  // border-bottom: 1px solid #ebebeb;
}
.upper-content .head-img {
  position: relative;
  margin-right: 30px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.upper-content .head-img img {
  width: 100%;
  border-radius: 50%;
}
.upper-content .head-img:hover .hover-change {
  display: block;
}
.upper-content .head-img .hover-change {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.upper-content .info {
  width: 698px;
}
.info-title {
  position: relative;
  width: 100%;
  height: 20px;
}
.info-title .entity-title {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}
.info-title .no-entity-title {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}
.info-title span:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 1;
  font-size: 14px;
  color: #8d8d8d;
}
.resetPaw {
  cursor: pointer;
}

.buttom-content {
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 20px;
  display: flex;
  height: 120px;
}
.buttom-content .download {
}
.buttom-content .high-com {
  margin-left: 50px;
}
.binding-text {
  margin: 30px 0;
}
.binding-account-card {
  margin-bottom: 75px;
}

.account-manage {
  .pinlessaccount-card {
    margin-top: 20px;
    .pinlessaccount-info {
      height: 100%;
      box-sizing: border-box;
      padding: 30px 10px 50px;
      .pinlessaccount-tittle {
        padding-bottom: 20px;
        span {
          color: #92b1ee;
        }
      }
      .pinlessaccount-info-card {
        .pinlessaccount-card-Item {
          width: 126px;
          height: 140px;
          border: 1px solid #edeff3;
          border-radius: 5px;
          padding: 16px;
          margin-right: 20px;
          div {
            color: #000000;
          }
          div:nth-child(1) {
            color: #c1c2c4;
          }
          div:nth-child(3) {
            color: #598aea;
          }
          &.pinlessaccount-card-Item:hover {
            background-color: #f7f8fa;
          }
        }
      }
    }
  }
}
.privilege-middle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #000000;
}
.privilege {
  display: flex;
  align-items: center;
  .privilege-mobile {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .privilege-image {
    width: 100px;
    height: 30px;
    background: #f6f3e7;
    border: 1px solid #e5ddbb;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .noPrivilege-image {
    width: 100px;
    height: 30px;
    background: #f5f5f5;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
}

/deep/.avatar-uploader .el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.img_box {
  font-size: 14px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  // border-radius: 50%;
}
.avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  // border: 1px solid red;
}
.button {
  text-align: center;
}
.text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 50px;
  color: #585858;
}
.bind-btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
}
.bind-btn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border: none;
  width: 320px;
  height: 46px;
  background: #156ed0;
  border-radius: 5px;
  cursor: pointer;
}
/deep/.el-input__inner {
  font-size: 16px;
  // width:150%;
  height: 50px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.left_label_color {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #646e7d;
  margin-right: 40px;
  width: 41px;
}
.right_label_weight_color {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.right_label_color {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.nickname {
  /deep/.el-input__inner {
    width: 120px;
    height: 30px;
    font-size: 14px;
    &::placeholder {
      font-size: 12px;
    }
  }
}
/deep/.el-form-item {
  width: 60%;
}
.creditCode {
  display: flex;
  .div-code {
    margin-right: 5px;
    text-align: center;
    /deep/.el-input__inner {
      width: 40px;
      height: 30px;
      font-size: 12px;
    }
  }
}
</style>
